import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Portfolio.css';
import projectOneImage from './image1.png';
import projectTwoImage from './image2.png';

function Portfolio() {
  return (
    <div className="portfolio">
      <header className="header">
        <Link to="/" className="header-link">
          <h1 className="header-title">Tumelo Modupi</h1>
        </Link>
      </header>
      
      <main className="portfolio-content">
        <div className="portfolio-grid">
          <a href="https://extraclasseshub.co.za" target="_blank" rel="noopener noreferrer" className="portfolio-item">
            <img src={projectOneImage} alt="Extra Classes Hub" className="portfolio-image" />
            <div className="portfolio-info">
              <h2 className="portfolio-title">Extra Classes Hub</h2>
              <p className="portfolio-description">A platform for booking extra classes and tutoring services.</p>
            </div>
          </a>
          <a href="https://picassophotographyservices.co.za" target="_blank" rel="noopener noreferrer" className="portfolio-item">
            <img src={projectTwoImage} alt="Picasso Photography Services" className="portfolio-image" />
            <div className="portfolio-info">
              <h2 className="portfolio-title">Picasso Photography Services</h2>
              <p className="portfolio-description">Photography services with a portfolio of stunning images.</p>
            </div>
          </a>
        </div>
      </main>
      
      <footer className="footer">
        <p>&copy; 2024 Tumelo Modupi. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Portfolio;
