import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Home.css';
import profilePic from './profile-pic.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="home">
      <Header />
      
      <main className="main-content">
        <div className="left-column">
          <div className="profile-section">
            <img src={profilePic} alt="Tumelo Modupi" className="profile-pic" />
            <h1 className="name">Tumelo Modupi</h1>
            <h2 className="title">Website Developer</h2>
            <button className="email-button" onClick={() => window.location.href = 'mailto:tumzena@gmail.com'}>Email me</button>
            <div className="social-icons">
              <a href="https://wa.me/27767362968?text=Hi%20Tumelo,%20I'd%20like%20to%20discuss%20a%20web%20development%20project." target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a href="https://www.linkedin.com/in/tumelo-modupi-ab5aba311?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href="tel:+27767362968" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faPhone} />
              </a>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="about-me-section">
            <h2 className="about-me-title">About Me</h2>
            <p className="about-me-description">I’m Tumelo Modupi, a passionate web developer with expertise in React.js, HTML, JavaScript, CSS, PHP, and UI design. I specialize in crafting dynamic and responsive web applications that blend functionality with aesthetics. My focus is on delivering high-quality digital experiences through user-centric design and clean code. Driven by a love for technology and continuous learning, I thrive on turning innovative ideas into effective web solutions. Let’s connect and build something great together!</p>
            <div className="buttons">
              <Link to="/portfolio" className="portfolio-button">Portfolio</Link>
            </div>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
}

export default Home;
